/**
 * 商品管理-商品-新增/编辑
 */
import reg from '../reg.js'
import common from './common.js'
const app_name = process.env.VUE_APP_NAME

function goodsManagerProductAdd (context) {
  const slaIndexName = {}
  const slaIndexNameEn = {}
  const ilaIndexName = {}
  const ilaIndexNameEn = {}
  const naIndexTitle = {}
  const naIndexTitleEn = {}
  const naIndexTitleDisplayOrder = {}
  const sisaIndexOnline = {}
  const sisaIndexName = {}
  const sisaIndexNameEn = {}
  const sisaIndexHostCommissionPercent = {}
  const sisaIndexPriceF = {}
  const sisaIndexCostPriceF = {}
  const sisaIndexLinePriceF = {}
  const sisaIndexSalingStock = {}
  const sisaIndexWarningStock = {}
  const sisaIndexLockingStock = {}
  const sipaIndexName = {}
  const sipaIndexDisplayOrder = {}
  const sipaIndexMinCount = {}
  const sipaIndexMaxCount = {}
  const sipaIndexpoaIndexName = {}
  const sipaIndexpoaIndexPriceF = {}
  const sisaIndexTierX_1 = {}
  if (context.mainForm) {
    context.mainForm.data.special_labels_attributes.forEach((item, index) => {
      // slaIndexName[`special_labels_attributes.${index}.name`] = [
      //   {
      //     required: true,
      //     message: context.$t('text.required'),
      //     trigger: 'blur'
      //   }
      // ]
      // slaIndexNameEn[`special_labels_attributes.${index}.name_en`] = [
      //   {
      //     required: true,
      //     message: context.$t('text.required'),
      //     trigger: 'blur'
      //   }
      // ]
    })
    context.mainForm.data.invisible_labels_attributes.forEach((item, index) => {
      // ilaIndexName[`invisible_labels_attributes.${index}.name`] = [
      //   {
      //     required: true,
      //     message: context.$t('text.required'),
      //     trigger: 'blur'
      //   }
      // ]
      // ilaIndexNameEn[`invisible_labels_attributes.${index}.name_en`] = [
      //   {
      //     required: true,
      //     message: context.$t('text.required'),
      //     trigger: 'blur'
      //   }
      // ]
    })
    context.mainForm.data.notices_attributes.forEach((item, index) => {
      naIndexTitle[`notices_attributes.${index}.title`] = [
        {
          required: true,
          message: context.$t('text.required'),
          trigger: 'blur'
        }
      ]
      naIndexTitleEn[`notices_attributes.${index}.title_en`] = [
        {
          required: false,
          message: context.$t('text.required'),
          trigger: 'blur'
        }
      ]
      naIndexTitleDisplayOrder[`notices_attributes.${index}.display_order`] = [
        {
          pattern: reg.number,
          message: context.$t('text.enterCorrectNumber'),
          trigger: 'blur'
        }
      ]
    })
    context.mainForm.data.shop_item_stocks_attributes.forEach((item, index) => {
      if (!item._destroy) {
        sisaIndexOnline[`shop_item_stocks_attributes.${index}.online`] = [
          {
            required: true,
            message: context.$t('text.required'),
            trigger: 'blur'
          }
        ]
        // sisaIndexName[`shop_item_stocks_attributes.${index}.name`] = [
        //   {
        //     required: true,
        //     message: context.$t('text.required'),
        //     trigger: 'blur'
        //   }
        // ]
        // sisaIndexNameEn[`shop_item_stocks_attributes.${index}.name_en`] = [
        //   {
        //     required: false,
        //     message: context.$t('text.required'),
        //     trigger: 'blur'
        //   }
        // ]
        sisaIndexHostCommissionPercent[`shop_item_stocks_attributes.${index}.host_commission_percent`] = [
          {
            validator: function (rule, value, callback, source, options) {
              common.percent(context, rule, value, callback, source, options)
            },
            trigger: 'blur'
          }
        ]
        sisaIndexPriceF[`shop_item_stocks_attributes.${index}.price_f`] = [
          {
            required: true,
            message: context.$t('text.required'),
            trigger: 'blur'
          },
          {
            pattern: reg.money,
            message: context.$t('text.enterCorrectAmount'),
            trigger: 'blur'
          }
        ]
        sisaIndexCostPriceF[`shop_item_stocks_attributes.${index}.cost_price_f`] = [
          {
            pattern: reg.money,
            message: context.$t('text.enterCorrectAmount'),
            trigger: 'blur'
          }
        ]
        sisaIndexLinePriceF[`shop_item_stocks_attributes.${index}.line_price_f`] = [
          {
            pattern: reg.money,
            message: context.$t('text.enterCorrectAmount'),
            trigger: 'blur'
          }
        ]
        sisaIndexSalingStock[`shop_item_stocks_attributes.${index}.saling_stock`] = [
          {
            required: true,
            message: context.$t('text.required'),
            trigger: 'blur'
          },
          {
            pattern: reg.number,
            message: context.$t('text.enterCorrectNumber'),
            trigger: 'blur'
          }
        ]
        sisaIndexWarningStock[`shop_item_stocks_attributes.${index}.warning_stock`] = [
          {
            required: true,
            message: context.$t('text.required'),
            trigger: 'blur'
          },
          {
            pattern: reg.number,
            message: context.$t('text.enterCorrectNumber'),
            trigger: 'blur'
          }
        ]
        if (app_name === 'combine') {
          sisaIndexTierX_1[`shop_item_stocks_attributes.${index}.tierX_1`] = [
            {
              required: true,
              message: context.$t('text.firstRequired'),
              trigger: 'blur'
            }
          ]
        }
      }
    })
    context.mainForm.data.shop_item_preferences_attributes.forEach((item, index) => {
      sipaIndexName[`shop_item_preferences_attributes.${index}.name`] = [
        {
          required: true,
          message: context.$t('text.required'),
          trigger: 'blur'
        }
      ]
      sipaIndexDisplayOrder[`shop_item_preferences_attributes.${index}.display_order`] = [
        {
          pattern: reg.number,
          message: context.$t('text.enterCorrectNumber'),
          trigger: 'blur'
        }
      ]
      sipaIndexMinCount[`shop_item_preferences_attributes.${index}.min_count`] = [
        {
          pattern: reg.number,
          message: context.$t('text.enterCorrectNumber'),
          trigger: 'blur'
        }
      ]
      sipaIndexMaxCount[`shop_item_preferences_attributes.${index}.max_count`] = [
        {
          pattern: reg.number,
          message: context.$t('text.enterCorrectNumber'),
          trigger: 'blur'
        }
      ]
      item.preference_options_attributes.forEach((subItem, subIndex) => {
        sipaIndexpoaIndexName[`shop_item_preferences_attributes.${index}.preference_options_attributes.${subIndex}.name`] = [
          {
            required: true,
            message: context.$t('text.required'),
            trigger: 'blur'
          }
        ]
        sipaIndexpoaIndexPriceF[`shop_item_preferences_attributes.${index}.preference_options_attributes.${subIndex}.price`] = [
          {
            required: true,
            message: context.$t('text.required'),
            trigger: 'blur'
          },
          {
            pattern: reg.money,
            message: context.$t('text.enterCorrectAmount'),
            trigger: 'blur'
          }
        ]
      })
    })
  }

  let mainForm = {
    online: [
      {
        required: true,
        message: context.$t('text.required'),
        trigger: 'blur'
      }
    ],
    recommend: [
      {
        required: true,
        message: context.$t('text.required'),
        trigger: 'blur'
      }
    ],
    support_seven_days_return: [
      {
        required: true,
        message: context.$t('text.required'),
        trigger: 'blur'
      }
    ],
    searchable: [
      {
        required: true,
        message: context.$t('text.required'),
        trigger: 'blur'
      }
    ],
    free_delivery: [
      {
        required: true,
        message: context.$t('text.required'),
        trigger: 'blur'
      }
    ],
    show_in_related_clients: [
      {
        required: true,
        message: context.$t('text.required'),
        trigger: 'blur'
      }
    ],
    online_time: [
      {
        required: false,
        message: context.$t('text.required'),
        trigger: 'blur'
      }
    ],
    shop_id: [
      {
        required: true,
        message: context.$t('text.required'),
        trigger: 'blur'
      }
    ],
    supplier_id: [
      {
        required: false,
        message: context.$t('text.required'),
        trigger: 'blur'
      }
    ],
    item_type: [
      {
        required: true,
        message: context.$t('text.required'),
        trigger: 'blur'
      }
    ],
    category_ids: [
      {
        required: false,
        message: context.$t('text.required'),
        trigger: 'blur'
      }
    ],
    shop_sub_category_ids: [
      {
        required: false,
        message: context.$t('text.required'),
        trigger: 'blur'
      }
    ],
    name: [
      {
        required: true,
        message: context.$t('text.required'),
        trigger: 'blur'
      }
    ],
    name_en: [
      {
        required: true,
        message: context.$t('text.required'),
        trigger: 'blur'
      }
    ],
    init_sale: [
      {
        required: false,
        message: context.$t('text.required'),
        trigger: 'blur'
      },
      {
        pattern: reg.number,
        message: context.$t('text.enterCorrectNumber'),
        trigger: 'blur'
      }
    ],
    maximum_limit_per_user: [
      {
        pattern: reg.number,
        message: context.$t('text.enterCorrectNumber'),
        trigger: 'blur'
      }
    ],
    short_description: [
      {
        required: false,
        message: context.$t('text.required'),
        trigger: 'blur'
      }
    ],
    short_description_en: [
      {
        required: false,
        message: context.$t('text.required'),
        trigger: 'blur'
      }
    ],
    description: [
      {
        required: false,
        message: context.$t('text.required'),
        trigger: 'blur'
      }
    ],
    description_en: [
      {
        required: false,
        message: context.$t('text.required'),
        trigger: 'blur'
      }
    ],
    global_labels: [
      {
        required: false,
        message: context.$t('text.required'),
        trigger: 'blur'
      }
    ],
    special_attributes: [
      {
        required: false,
        message: context.$t('text.required'),
        trigger: 'blur'
      }
    ],
    ...slaIndexName,
    ...slaIndexNameEn,
    ...ilaIndexName,
    ...ilaIndexNameEn,
    ...naIndexTitle,
    ...naIndexTitleEn,
    ...naIndexTitleDisplayOrder,
    ...sisaIndexOnline,
    ...sisaIndexName,
    ...sisaIndexNameEn,
    ...sisaIndexHostCommissionPercent,
    ...sisaIndexPriceF,
    ...sisaIndexCostPriceF,
    ...sisaIndexLinePriceF,
    ...sisaIndexSalingStock,
    ...sisaIndexWarningStock,
    ...sisaIndexLockingStock,
    ...sipaIndexName,
    ...sipaIndexDisplayOrder,
    ...sipaIndexMinCount,
    ...sipaIndexMaxCount,
    ...sipaIndexpoaIndexName,
    ...sipaIndexpoaIndexPriceF
  }
  if (app_name === 'combine') {
    mainForm = {
      ...mainForm,
      wholesale_type: [
        {
          required: true,
          message: context.$t('text.required'),
          trigger: 'blur'
        }
      ],
      ...sisaIndexTierX_1
    }
  }
  return {
    mainForm
  }
}

export default goodsManagerProductAdd
