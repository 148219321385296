// 通用
const common = {
  percent: function (context, rule, value, callback, source, options) {
    if (!value) {
      callback()
    }
    if (Number(value) || Number(value) === 0) {
      if (value > 100 || value < 0) {
        callback(new Error(context.$t('text.enterCorrectPercentage')))
      } else {
        callback()
      }
    } else {
      return callback(new Error(context.$t('text.enterCorrectPercentage')))
    }
  }
}

export default common
