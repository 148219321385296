/**
 * 团长管理-地址类型-新增/编辑
 */
// import reg from '../reg.js'

function buddyManagerAddressTypeAdd (context) {
  return {
    mainForm: {
      name: [{ required: true, message: context.$t('text.required'), trigger: 'blur' }],
      name_en: [{ required: true, message: context.$t('text.required'), trigger: 'blur' }]
    }
  }
}

export default buddyManagerAddressTypeAdd
