/**
 * 团长管理-团长提成-新增/编辑
 */
import reg from '../reg.js'

function buddyManagerCommissionAdd (context) {
  return {
    mainForm: {
      commission_type: [{ required: true, message: context.$t('text.required'), trigger: 'blur' }],
      host_phone_number: [{ required: true, message: context.$t('text.required'), trigger: 'blur' }],
      commission_amount_f: [
        {
          required: true,
          message: context.$t('text.required'),
          trigger: 'blur'
        },
        {
          pattern: reg.money,
          message: context.$t('text.enterCorrectAmount'),
          trigger: 'blur'
        }
      ]
    }
  }
}

export default buddyManagerCommissionAdd
