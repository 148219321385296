import reg from '../reg.js'

function rewardsStoreProductCategoriesAdd (context) {
  return {
    mainForm: {
      online: [{ required: true, message: context.$t('text.required'), trigger: 'blur' }],
      name: [{ required: true, message: context.$t('text.required'), trigger: 'blur' }],
      name_en: [{ required: false, message: context.$t('text.required'), trigger: 'blur' }],
      remote_icon_url: [{ required: true, message: context.$t('text.required'), trigger: 'blur' }],
      rank: [
        {
          required: false,
          message: context.$t('text.required'),
          trigger: 'blur'
        },
        {
          pattern: reg.number,
          message: context.$t('text.enterCorrectNumber'),
          trigger: 'blur'
        }
      ]
    }
  }
}

export default rewardsStoreProductCategoriesAdd
