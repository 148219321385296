/**
 * 商品管理-商品类别-新增/编辑
 */
// import reg from '../reg.js'
const app_name = process.env.VUE_APP_NAME

function goodsManagerCategoriesAdd (context) {
  let mainForm = {
    online: [{ required: true, message: context.$t('text.required'), trigger: 'blur' }],
    shop_id: [{ required: true, message: context.$t('text.required'), trigger: 'blur' }],
    // name: [{ required: true, message: context.$t('text.required'), trigger: 'blur' }],
    // name_en: [{ required: true, message: context.$t('text.required'), trigger: 'blur' }],
    compulsory_items: [{ required: true, message: context.$t('text.required'), trigger: 'blur' }]
  }
  if (app_name === 'combine') {
    mainForm = {
      ...mainForm,
      wholesale_type: [
        {
          required: true,
          message: context.$t('text.required'),
          trigger: 'blur'
        }
      ]
    }
  }
  return {
    mainForm
  }
}

export default goodsManagerCategoriesAdd
