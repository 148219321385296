import goodsManagerVariationsAdd from './rules/goodsManagerVariationsAdd.js'
import goodsManagerCategoriesAdd from './rules/goodsManagerCategoriesAdd.js'
import goodsManagerSubCategoriesAdd from './rules/goodsManagerSubCategoriesAdd.js'
import goodsManagerCommonLabelsAdd from './rules/goodsManagerCommonLabelsAdd.js'
import goodsManagerProductAdd from './rules/goodsManagerProductAdd.js'
import buddyManagerBuddyAdd from './rules/buddyManagerBuddyAdd.js'
import buddyManagerAddressAdd from './rules/buddyManagerAddressAdd.js'
import buddyManagerAddressTypeAdd from './rules/buddyManagerAddressTypeAdd.js'
import buddyManagerCommissionAdd from './rules/buddyManagerCommissionAdd.js'
import rewardsStoreRewardsProductAdd from './rules/rewardsStoreRewardsProductAdd.js'
import rewardsStoreProductCategoriesAdd from './rules/rewardsStoreProductCategoriesAdd.js'

const verifyRules = function (context) {
  return {
    goodsManagerVariationsAdd: goodsManagerVariationsAdd(context),
    goodsManagerCategoriesAdd: goodsManagerCategoriesAdd(context),
    goodsManagerSubCategoriesAdd: goodsManagerSubCategoriesAdd(context),
    goodsManagerCommonLabelsAdd: goodsManagerCommonLabelsAdd(context),
    goodsManagerProductAdd: goodsManagerProductAdd(context),
    buddyManagerBuddyAdd: buddyManagerBuddyAdd(context),
    buddyManagerAddressAdd: buddyManagerAddressAdd(context),
    buddyManagerAddressTypeAdd: buddyManagerAddressTypeAdd(context),
    buddyManagerCommissionAdd: buddyManagerCommissionAdd(context),
    rewardsStoreRewardsProductAdd: rewardsStoreRewardsProductAdd(context),
    rewardsStoreProductCategoriesAdd: rewardsStoreProductCategoriesAdd(context)
  }
}

export default verifyRules
