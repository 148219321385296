const reg = {
  // 金额
  money: /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/,
  // 手机号
  phone: /^1\d{10}$/,
  // 身份证号
  idCard: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/gi,
  // 用户名
  username: /^[0-9a-zA-Z]{8,20}$/,
  // 纯数字
  number: /^\d*$/,
  // 纯字母
  letter: /^[a-z]*$/i,
  // 数字和字母
  numberAndLetter: /^[a-z\d]*$/i,
  // 6-12位的数字和字母的组合密码
  password: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,12}$/,
  // 中国手机号
  mobliePhone: /^1\d{10}/
}

export default reg
