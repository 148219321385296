import reg from '../reg.js'

function rewardsStoreRewardsProductAdd (context) {
  return {
    mainForm: {
      online: [{ required: true, message: context.$t('text.required'), trigger: 'blur' }],
      rewardable_type: [{ required: true, message: context.$t('text.required'), trigger: 'blur' }],
      rewardable_id: [{ required: true, message: context.$t('text.required'), trigger: 'blur' }],
      reward_product_category_id: [{ required: true, message: context.$t('text.required'), trigger: 'blur' }],
      point_price: [
        {
          required: true,
          message: context.$t('text.required'),
          trigger: 'blur'
        },
        {
          pattern: reg.number,
          message: context.$t('text.enterCorrectNumber'),
          trigger: 'blur'
        }
      ],
      rank: [
        {
          required: false,
          message: context.$t('text.required'),
          trigger: 'blur'
        },
        {
          pattern: reg.number,
          message: context.$t('text.enterCorrectNumber'),
          trigger: 'blur'
        }
      ]
    }
  }
}

export default rewardsStoreRewardsProductAdd
