/**
 * 团长管理-团长列表-新增/编辑
 */
// import reg from '../reg.js'

function buddyManagerBuddyAdd (context) {
  return {
    mainForm: {
      online: [{ required: true, message: context.$t('text.required'), trigger: 'blur' }],
      support_refrigerated_products: [{ required: true, message: context.$t('text.required'), trigger: 'blur' }],
      support_frozen_products: [{ required: true, message: context.$t('text.required'), trigger: 'blur' }],
      zero_commission: [{ required: true, message: context.$t('text.required'), trigger: 'blur' }],
      host_type: [{ required: true, message: context.$t('text.required'), trigger: 'blur' }]
    }
  }
}

export default buddyManagerBuddyAdd
