/**
 * 商品管理-商品标签-新增/编辑
 */
// import reg from '../reg.js'

function goodsManagerCommonLabelsAdd (context) {
  return {
    mainForm: {
      // name: [{ required: true, message: context.$t('text.required'), trigger: 'blur' }]
      // name_en: [{ required: true, message: context.$t('text.required'), trigger: 'blur' }]
    }
  }
}

export default goodsManagerCommonLabelsAdd
