/**
 * 团长管理-团长地址-新增/编辑
 */
// import reg from '../reg.js'

function buddyManagerAddressAdd (context) {
  return {
    mainForm: {
      host_id: [{ required: true, message: context.$t('text.required'), trigger: 'blur' }]
    }
  }
}

export default buddyManagerAddressAdd
