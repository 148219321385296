/**
 * 商品管理-商品型号-新增/编辑
 */
import reg from '../reg.js'
import common from './common.js'
const app_name = process.env.VUE_APP_NAME

function goodsManagerVariationsAdd (context) {
  const mainForm = {
    online: [{ required: true, message: context.$t('text.required'), trigger: 'blur' }],
    shop_item_id: [{ required: true, message: context.$t('text.required'), trigger: 'blur' }],
    // name: [{ required: true, message: context.$t('text.required'), trigger: 'blur' }],
    // name_en: [{ required: true, message: context.$t('text.required'), trigger: 'blur' }],
    price_f: [{
      required: true, message: context.$t('text.required'), trigger: 'blur'
    }, {
      pattern: reg.money,
      message: context.$t('text.enterCorrectAmount'),
      trigger: 'blur'
    }],
    cost_price_f: [{
      pattern: reg.money,
      message: context.$t('text.enterCorrectAmount'),
      trigger: 'blur'
    }],
    line_price_f: [{
      pattern: reg.money,
      message: context.$t('text.enterCorrectAmount'),
      trigger: 'blur'
    }],
    host_commission_percent: [{
      validator: function (rule, value, callback, source, options) {
        common.percent(context, rule, value, callback, source, options)
      },
      trigger: 'blur'
    }],
    saling_stock: [{
      required: true, message: context.$t('text.required'), trigger: 'blur'
    }, {
      pattern: reg.number,
      message: context.$t('text.enterCorrectNumber'),
      trigger: 'blur'
    }],
    warning_stock: [{
      required: true, message: context.$t('text.required'), trigger: 'blur'
    }, {
      pattern: reg.number,
      message: context.$t('text.enterCorrectNumber'),
      trigger: 'blur'
    }]
  }
  const support_wholesale = [{ required: true, message: context.$t('text.required'), trigger: 'blur' }]
  const tierX_1 = [{ required: true, message: context.$t('text.firstRequired'), trigger: 'blur' }]
  if (app_name === 'combine') {
    Object.assign(mainForm, {
      support_wholesale,
      tierX_1
    })
  }
  return {
    mainForm
  }
}

export default goodsManagerVariationsAdd
